import StylesBuilder from "architecture/styles/StylesBuilder";
import { HeaderPage } from "shared/ui/page";
import stylesM from "./mainPage.module.css";
import { Button, SquareButton } from "shared/ui/buttons";
import { url } from "inspector";
import Select from "shared/ui/select/classic/Select";
import Lookup from "architecture/types/Lookup";
import { useNavigate } from "react-router-dom";
import InfoPageTemplate from "pages/InfoPage/InfoPage";

function PeloplesPage() {

    const navigate = useNavigate();

    const wrraperClassName = StylesBuilder.use()
        .row()
        .justCenter()
        .alignCenter()
        .widthFull()
        .heightFull()
        .contentPaddig()
        .get() + " " + stylesM.background;

    const styles = StylesBuilder.use().panel().content().get()

    const buttonPanelStyles = StylesBuilder.use().widthFull().column().gap().get() + " " + stylesM.button;

    const buttonStyles = StylesBuilder.use().column().gap().get();

    return (
        <InfoPageTemplate title="Мир" audioCatch={[0, 125, 310]} audio={new Audio("./static/legends.mp3")} options={[{id: 1, displayValue: "Люди"}, {id: 2, displayValue: "Фальты"}, {id: 3, displayValue: "Карлы"}, {id: 3, displayValue: "Ваны"}, {id: 3, displayValue: "Ётуны"}, {id: 3, displayValue: "Ассы"}]} >
            <div>
                <h2 id="winter" style={{textAlign: "center", fontSize: "60px", margin: "0"}}>*** Народы мидгарда ***</h2>
                <p style={{fontSize: "30px"}}>
                    <b>Люди</b> - все известны эти создания ) Их быт приближён к жителям скандинавских стран. В качестве богов они почитают в первую очередь Ассов, а в вторую Ваннов. Периодически воздавая им молитвы и принося жертвы.
                </p>
                <p style={{fontSize: "30px"}}>
                    <b>Фальты</b> - потомки людей и ётунов. Они очень похоже на людей, за одним исключением, их рост варьируется от 2 - 2.20 метров, а их волосы белоснежны. Они почти не ощущают холода и могу прожить без пищи и воды больше чем обычные люди. Люди воют с ними, как с потомками врагов людей и предателями. 
                </p>
                <p style={{fontSize: "30px"}}>
                    <b>Карлы</b> - хоть они и живут глубоко под землёй, но они часто выходят торговать с людьми. Они продаёт людям, броню, артефакты и драгоценности, а люди их снабжают пищей и ресурсами, что можно добыть только на поверхности.
                </p>
            </div>
        </InfoPageTemplate>
    );
}

export default PeloplesPage;
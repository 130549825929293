import styles from "./Styles.module.css";

class StylesBuilder{
    classNames: string[] = [];

    content() : StylesBuilder{
        this.classNames.push(styles.content);
        return this;
    }

    row():StylesBuilder{
        this.classNames.push(styles.row);
        return this;
    }
    
    column() : StylesBuilder{
        this.classNames.push(styles.column);
        return this;
    }
    
    gap() : StylesBuilder{
        this.classNames.push(styles.gap);
        return this;
    }
    
    marginRight() : StylesBuilder{
        this.classNames.push(styles.marginRight);
        return this;
    }
    
    alignRight() : StylesBuilder{
        this.classNames.push(styles.alignRight);
        return this;
    }
    
    alignLeft() : StylesBuilder{
        this.classNames.push(styles.alignLeft);
        return this;
    }

    alignCenter() : StylesBuilder{
        this.classNames.push(styles.alignCenter);
        return this;
    }
    
    justRight() : StylesBuilder{
        this.classNames.push(styles.justRight);
        return this;
    }
    
    justLeft() : StylesBuilder{
        this.classNames.push(styles.justLeft);
        return this;
    }

    justCenter() : StylesBuilder{
        this.classNames.push(styles.justCenter);
        return this;
    }
    
    justBetween() : StylesBuilder{
        this.classNames.push(styles.justBetween);
        return this;
    }
    
    displayNone() : StylesBuilder{
        this.classNames.push(styles.displayNone);
        return this;
    }

    errorPanel() : StylesBuilder{
        this.classNames.push(styles.errorPanel);
        return this;
    }
    
    successPanel() : StylesBuilder{
        this.classNames.push(styles.successPanel);
        return this;
    }

    warrningPanel() : StylesBuilder{
        this.classNames.push(styles.warrningPanel);
        return this;
    }
    
    errorMessage() : StylesBuilder{
        this.classNames.push(styles.errorMessage);
        return this;
    }
    
    successMessage() : StylesBuilder{
        this.classNames.push(styles.successMessage);
        return this;
    }

    widthFull() : StylesBuilder{
        this.classNames.push(styles.widthFull);
        return this;
    }

    heightFull() : StylesBuilder{
        this.classNames.push(styles.heightFull);
        return this;
    }

    flexBasisFull(): StylesBuilder{
        this.classNames.push(styles.flexBasisFull);
        return this;
    }
    
    wrap() : StylesBuilder{
        this.classNames.push(styles.wrap);
        return this;
    }

    transition() : StylesBuilder{
        this.classNames.push(styles.transition);
        return this;
    }

    panel() : StylesBuilder{
        this.classNames.push(styles.panel);
        return this;
    }

    contentPaddig() : StylesBuilder{
        this.classNames.push(styles.contentPaddig);
        return this;
    }

    get(){
        return this.classNames.join(" ");
    }

    static use(){
        return new StylesBuilder;
    }
}

export default StylesBuilder;
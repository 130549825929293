import StylesBuilder from "architecture/styles/StylesBuilder";
import { HeaderPage } from "shared/ui/page";
import stylesM from "./mainPage.module.css";
import { Button, SquareButton } from "shared/ui/buttons";
import { url } from "inspector";
import Select from "shared/ui/select/classic/Select";
import Lookup from "architecture/types/Lookup";
import { useNavigate } from "react-router-dom";
import InfoPageTemplate from "pages/InfoPage/InfoPage";

function WorldPage() {

    const navigate = useNavigate();

    const wrraperClassName = StylesBuilder.use()
        .row()
        .justCenter()
        .alignCenter()
        .widthFull()
        .heightFull()
        .contentPaddig()
        .get() + " " + stylesM.background;

    const styles = StylesBuilder.use().panel().content().get()

    const buttonPanelStyles = StylesBuilder.use().widthFull().column().gap().get() + " " + stylesM.button;

    const buttonStyles = StylesBuilder.use().column().gap().get();

    return (
        <InfoPageTemplate title="Мир" audioCatch={[0, 125, 310]} audio={new Audio("./static/legends.mp3")} options={[{id: 1, displayValue: "О девяти мирах"}, {id: 2, displayValue: "Мидград"}, {id: 3, displayValue: "Ванахейм"}, {id: 3, displayValue: "Свартальфахейм"}, {id: 3, displayValue: "Ётунхейм"}, {id: 3, displayValue: "Фимбулвинтер"}]} >
            <div>
                <h2 id="winter" style={{textAlign: "center", fontSize: "60px", margin: "0"}}>*** Фимбулвинтер ***</h2>
                <p style={{fontSize: "30px"}}>
                    <b>Фимбулвинтер</b> - страшная зима, которая должна была продлиться три года. Но пророчество о великанской зиме оказалось слегка неточным, и она растянулась на десятки лет. Страшный холод зимы уничтожил всё живое, что не было обогрето источником жизни. Когда-то живописные и зелёные луга и леса превратились в безжизненную ледяную пустыню, подобную Ётунхейму.
                </p>
            </div>
        </InfoPageTemplate>
    );
}

export default WorldPage;
import { observable, reaction } from "mobx";
import { Buffer } from 'buffer';

function parseJwt(token: string) {
    const base64Payload = token.split('.')[1];
    const payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
}

// это наш стор для логина
const loginStore = observable({
    token : window.localStorage.getItem('jwt'),
    
    get loggedIn():boolean {
        return (this.token !== undefined && this.token !== null);
    },

    get userId():string | null {
        if(loginStore.loggedIn) {
            const jwt = parseJwt(loginStore.token!);
            return jwt.Sub;
        }
        return null;
    },

    get useRole(): string | null{
        if(loginStore.loggedIn) {
            const jwt = parseJwt(loginStore.token!);
            return Reflect.get(jwt, "http://schemas.microsoft.com/ws/2008/06/identity/claims/role");
        }
        return null;
    },

    logIn(token: string) {
        this.token = token;
    },
    logOut() {
        this.token = null;
    }
});

reaction(
    () => loginStore.token,
    token => {
        if (token) {
            window.localStorage.setItem('jwt', token);
        } else {
            window.localStorage.removeItem('jwt');
        }
    }
);

export default loginStore;
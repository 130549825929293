import StylesBuilder from "../../../../architecture/styles/StylesBuilder";

interface ToolPanelProps{
    children: any;
}

function ToolPanel(props:ToolPanelProps){
    const childClassName = StylesBuilder.use()
        .row()
        .gap()
        .widthFull()
        .justLeft()
        .get();
        
    return(
        <div className={childClassName}>
            {props.children}
        </div>
    )
}

export default ToolPanel;
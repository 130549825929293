import styles from "./SquareButton.module.css";

export enum SquareButtonStyle{
    classic,
    mini
}

interface SquareButtonProps{
    children:any;
    onClick?:()=>void;
    style?:SquareButtonStyle;
}

function SquareButton(props:SquareButtonProps){

    let className = "";

    if(props.style === undefined || props.style === SquareButtonStyle.classic){
        className = styles.squareButton;
    }
    else if(props.style === SquareButtonStyle.mini){
        className = styles.squareButtonMini;
    }

    return (
        <div id="buttonMenu" className={className} onClick={props.onClick}>
            {props.children}
        </div>
    )
}

export default SquareButton;
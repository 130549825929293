import StylesBuilder from "architecture/styles/StylesBuilder";
import { HeaderPage } from "shared/ui/page";
import stylesM from "./mainPage.module.css";
import { Button, SquareButton } from "shared/ui/buttons";
import { url } from "inspector";
import Select from "shared/ui/select/classic/Select";
import Lookup from "architecture/types/Lookup";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";

function InfoPageTemplate (props: {
    title: string,
    children: JSX.Element
    audio: HTMLAudioElement
    audioCatch: number[]
    options: Lookup[]
}) {

    const navigate = useNavigate();

    const wrraperClassName = StylesBuilder.use()
        .row()
        .justCenter()
        .alignCenter()
        .widthFull()
        .heightFull()
        .contentPaddig()
        .get() + " " + stylesM.background;

    const styles = StylesBuilder.use().panel().content().get()

    const buttonPanelStyles = StylesBuilder.use().widthFull().column().gap().get() + " " + stylesM.button;

    const audio = useRef(props.audio);

    function goTo(id: Lookup) {
        // eslint-disable-next-line no-restricted-globals
        window.location.hash = `#l${id.id}`
        audio.current.currentTime = props.audioCatch[id.id-1]
    }

    function play() {
        console.log(audio.current)
        if (!audio.current.paused){
            console.log("played")
            audio.current.pause()
        }
        else{
            console.log("played + ")
            audio.current.play()
        }
    }

    return (
        <div className={wrraperClassName}> 
            <div className={stylesM.snowblock} />  
            <div className={styles}>
                <HeaderPage title={props.title}>
                    <Button disabled onClick={play} >Зачитать</Button>
                    <Button onClick={() => {navigate("/")}} >На главную</Button>
                    <Select options={props.options} onChange={goTo} />
                </HeaderPage>
                <div className={buttonPanelStyles}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default InfoPageTemplate;
import React, { createElement, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, Route, Router, RouterProvider, Routes } from 'react-router-dom';
import loginStore from 'architecture/stores/LoginStore';
import { observer } from 'mobx-react-lite';
import eventBus from 'architecture/EventBus';
import DialogEvent from 'entity/dialogEvent';
import { createPortal } from 'react-dom';
import ActionDialogEvent from 'entity/actionDialogEvent';
import MainPage from 'pages/main/mainPage';
import WorldPage from 'pages/worldPage/worldPage';
import LegendsPage from 'pages/legends/worldPage';
import PeloplesPage from 'pages/peples/peoplasPage';

function App() {
  return (
      <RoutesApp/>
  );
}


const RoutesApp = observer(()=>{
  return(
    <div className="App">
      <Routes>
        <Route path='/' element={<MainPage/>} />
        <Route path='/world' element={<WorldPage/>} />LegendsPage
        <Route path='/legends' element={<LegendsPage/>} />
        <Route path='/folklore' element={<PeloplesPage/>} />
      </Routes>
    </div>
  );
});

export default App;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import axios, { AxiosError } from 'axios';
import loginStore from './architecture/stores/LoginStore';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



async function login(){
  axios.defaults.baseURL = "http://localhost:5252"; //"http://oscreator.ru:8031";
  
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        loginStore.logOut();
      }
      return Promise.reject(error);
    }
  );
 
  axios.interceptors.request.use((config) => {
    const token = loginStore.token; // получаем токен из состояния хранилища
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // добавляем токен к заголовкам запроса
    }
    return config;
  });

  root.render(
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  );
}

login();

import StylesBuilder from "../../../../architecture/styles/StylesBuilder";
import styles from "./HeaderPage.module.css";

interface IHeaderProps{
    title: string
    children?: any
}

function HeaderPage(props:IHeaderProps){
    
    const wrraperClassName = StylesBuilder.use()
        .row()
        .justBetween()
        .alignCenter()
        .get();

    const childClassName = StylesBuilder.use()
        .row()
        .gap()
        .get();

    return(
        <div className={wrraperClassName + " " + styles.header}>
            <h1>{props.title}</h1>
            <div className={childClassName + " " + styles.header}>
                {props.children}
            </div>
        </div>
    )
}

export default HeaderPage;
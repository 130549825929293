import StylesBuilder from "architecture/styles/StylesBuilder";
import { HeaderPage } from "shared/ui/page";
import stylesM from "./mainPage.module.css";
import { Button, SquareButton } from "shared/ui/buttons";
import { url } from "inspector";
import { useNavigate } from "react-router-dom";

function MainPage() {

    const navigate = useNavigate();

    const wrraperClassName = StylesBuilder.use()
        .row()
        .justCenter()
        .alignCenter()
        .widthFull()
        .heightFull()
        .contentPaddig()
        .get() + " " + stylesM.background + " ";

    const styles = StylesBuilder.use().panel().content().get()

    const buttonPanelStyles = StylesBuilder.use().widthFull().row().gap().wrap().justCenter().get() + " " + stylesM.button;

    const buttonStyles = StylesBuilder.use().justCenter().alignCenter().column().gap().get();

    return (
        <div className={wrraperClassName}>   
            <div className={stylesM.snowblock} />
            <div className={styles}>
                <HeaderPage title="Главная страница">
                    <SquareButton onClick={() => {window.location.replace("https://t.me/masscrafting")}} > <img width={32} src="./static/5968804.png" /> </SquareButton>
                </HeaderPage>
                <div className={buttonPanelStyles}>
                    <Button onClick={() => {}} disabled>
                        <div className={buttonStyles}>
                            <img className={stylesM.intro} src="./static/cf2d01377e6e4d81b818834067cba81e.jpeg" />
                            Города
                        </div>
                    </Button>

                    <Button onClick={() => {navigate("/folklore")}}>
                        <div className={buttonStyles}>
                            <img className={stylesM.intro} src="./static/scandinavias-folklore-characters-including-carlsman-people-and-jotun-for-a-book-cover-blended-in.jpeg" />
                            Народы
                        </div>
                    </Button>

                    <Button onClick={() => {navigate("/world")}} >
                        <div className={buttonStyles}>
                            <img className={stylesM.intro} src="./static/24c7a93ff7fc4feaaab4bec014400b99.jpeg" />
                            Мир
                        </div>
                    </Button>

                    <Button onClick={() => {}} disabled>
                        <div className={buttonStyles}>
                            <img className={stylesM.intro} src="./static/6320fe47f4614cd28cf4a97056cbb1bd.jpeg" />
                            Герои
                        </div>
                    </Button>

                    <Button onClick={() => {navigate("/legends")}} >
                        <div className={buttonStyles}>
                            <img className={stylesM.intro} src="./static/bb8735cc6a5444c78747fe3b8a74495b.jpeg" />
                            Легенды
                        </div>
                    </Button>
                </div>
                
            </div>
        </div>
    );
}

export default MainPage;